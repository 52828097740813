'use client';

import React from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import CenteredCarousel from '@src/components/Shared/CenteredCarousel';
import InlineSVG from '@src/components/Shared/InlineSVG';
import NextImage from '@src/components/Shared/NextImage';
import HeroValdProd from '@src/components/Sections/HeroValdProd';
import ImageTextSecondaryWIthSlideAnimation from '@src/components/Sections/ImageTextSecondaryWIthSlideAnimation';
import ImageTextSecondary from '@src/components/Sections/ImageTextSecondary';
import ForceDecksBlogsCarousel from '@src/components/Sections/ForceDecksBlogsCarousel';
import FeaturedProducts from '@src/components/Sections/FeaturedProducts';
import CallToAction from '@src/components/Sections/CallToAction';
import usePreviewContent from '@src/hooks/usePreviewContent';
import constructURL from '@src/utils/constructURL';
import { ForceDecksPageClientPropTypes } from './ForceDecksPage.types';
const ForceDecksPageClient: React.FC<ForceDecksPageClientPropTypes> = ({
  data
}) => {
  // Enable live updates for the post
  const page = usePreviewContent({
    content: data?.page
  });

  // Set the page view path for analytics tracking
  const {
    setPathPageView,
    setContentType
  } = useTrackingCode();
  setPathPageView(constructURL({
    languageCode: page?.languageCode?.toLowerCase(),
    slug: `/products/forcedecks`,
    baseURL: ''
  }));
  setContentType('site-page');
  return <>
      <HeroValdProd id={page?.hero?.items?.[0]?.scrollTo?.slice(1)} ctfData={page?.hero?.items?.[0]} wrapClasses='!mt-[4rem] lg:!mb-[6.5rem] 2xl:!mt-[-2rem]' imgClassName='md:absolute md:right-[0] md:w-[55rem] md:bottom-[2rem] lg:relative lg:w-[60rem] lg:bottom-[-2rem] 2xl:left-[5rem] 2xl:w-[80rem] 3xl:left-[-10rem] xsm:min-h-[20.5797rem] md:min-h-[30rem] lg:min-h-[33rem] 2xl:min-h-[35.3rem] 3xl:min-h-[38.2484rem]' logoClassName='mt-[3rem] md:mt-[5.7rem] max-w-[27.6386rem] mb-[2.4rem] md:max-w-[25.7rem] md:mt-[4.1rem] lg:max-w-[35.9302rem] 2xl:max-w-[53.96rem] xsm:min-h-[5.3219rem] md:min-h-[4.9484rem] lg:min-h-[6.9188rem] 2xl:min-h-[10.3906rem]' introClassName='tracking-[-0.0342rem] leading-[2.7rem] text-[1.8rem] max-w-[30.5rem] md:text-[2rem] md:leading-[3rem] md:tracking-[-0.036rem] md:max-w-[39.6rem] lg:max-w-[48.5rem] lg:text-[2.4rem] lg:leading-[3.6rem] 2xl:max-w-[58.3rem]' headerClassName='xsm:mx-auto md:mx-[unset] md:w-[80%] md:self-start lg:self-center lg:w-[50%]' data-sentry-element="HeroValdProd" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <ImageTextSecondaryWIthSlideAnimation id={page?.easeOfUse?.items?.[0]?.scrollTo?.slice(1)} className='py-[5rem] lg:py-[0] xl:!overflow-visible 2xl:!py-[3rem] 3xl:!py-[8rem] [&>div>header>h2>span]:absolute [&>div>header>h2>span]:top-[-0.2rem] [&>div>header>h2>span]:ml-[0.8rem] [&>div>header>h2>span]:leading-[2.3rem] [&>div>header>h2>span]:opacity-[0]  lg:[&>div>header>h2>span]:top-[unset] lg:[&>div>header>h2>span]:ml-[1.3rem] lg:[&>div>header>h2>span]:leading-[unset]' background='bg-background-primary' reversed slide pretitle={page?.easeOfUse?.items?.[0]?.preTitle} title={page?.easeOfUse?.items?.[0]?.title} titleClassName='text-body-copy--light !text-[2.6rem] lg:!text-[4.8rem]' imgURL={page?.easeOfUse?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.easeOfUse?.items?.[0]?.mediaPrimary?.description} imgSecondClassName='xl:!absolute xl:bottom-[0] xl:h-[118%] xl:right-[0]' textContainerClassName='xl:!w-[45%]' objectFit='contain' childrenElements={<div className='text-body-copy--light'>
            <RenderBodyCopy className='max-w-[51.7rem] text-left 2xl:max-w-[78.2rem]' bodyCopy={page?.easeOfUse?.items?.[0]?.bodyCopy?.json?.content} />

            <RenderButtons items={page?.easeOfUse?.items?.[0]?.buttonCollection?.items} />
          </div>} data-sentry-element="ImageTextSecondaryWIthSlideAnimation" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <ImageTextSecondary className='lg:py-[0]' background='lg:!ml-[unset] lg:!mr-[unset] lg:!w-[100%] ' id={page?.usability?.items?.[0]?.scrollTo?.slice(1)} pretitle={page?.usability?.items?.[0]?.preTitle} title={page?.usability?.items?.[0]?.title} imgURL={page?.usability?.items?.[0]?.mediaPrimary?.url} titleClassName='md:max-w-[40rem]' imgAlt={page?.usability?.items?.[0]?.mediaPrimary?.description} objectFit='contain' imgSecondClassName='lg:!w-[70%]' childrenElements={<>
            <RenderBodyCopy className='max-w-[51.7rem] text-left 2xl:max-w-[78.2rem]' bodyCopy={page?.usability?.items?.[0]?.bodyCopy?.json?.content} />

            <RenderButtons className='w-full' items={page?.usability?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <Container id={page?.versatility?.items?.[0]?.scrollTo?.slice(1)} className='py-[7.4rem] md:flex md:flex-col' background='bg-background-tertiary' data-sentry-element="Container" data-sentry-source-file="ForceDecksPage.client.tsx">
        <SectionHeader className='justify-center lg:pb-[unset]' pretitle={page?.versatility?.items?.[0]?.preTitle} title={page?.versatility?.items?.[0]?.title} titleClassName='max-w-[71.6rem] text-[2.8rem] mb-[2.4rem] leading-[3.2rem] md:leading-[4.6rem] lg:text-[4.8rem] lg:leading-[5rem]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="ForceDecksPage.client.tsx">
          <>
            <RenderBodyCopy className=' 2xl:max-w-[78.2rem]' bodyCopy={page?.versatility?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="ForceDecksPage.client.tsx" />

            <RenderButtons className='mb-[6rem] w-full justify-center' items={page?.versatility?.items?.[0]?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="ForceDecksPage.client.tsx" />
          </>
        </SectionHeader>

        <CenteredCarousel list={page?.versatility?.items?.[0]?.extraAssetsCollection?.items} data-sentry-element="CenteredCarousel" data-sentry-source-file="ForceDecksPage.client.tsx" />
      </Container>

      <ImageTextSecondary id={page?.togetherAtLast?.items?.[0]?.scrollTo?.slice(1)} background='lg:!ml-[unset] lg:!mr-[unset] lg:!w-[100%]' pretitle={page?.togetherAtLast?.items?.[0]?.preTitle} title={page?.togetherAtLast?.items?.[0]?.title} titleClassName='md:max-w-[46rem]' imgURL={page?.togetherAtLast?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.togetherAtLast?.items?.[0]?.mediaPrimary?.description} objectFit='contain' childrenElements={<>
            <RenderBodyCopy className='2xl:max-w-[78.2rem]' bodyCopy={page?.togetherAtLast?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.togetherAtLast?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <ImageTextSecondary reversed id={page?.introducingNorms?.items?.[0]?.scrollTo?.slice(1)} className='py-[5rem] 2xl:!overflow-visible' background='bg-background-primary' pretitle={page?.introducingNorms?.items?.[0]?.preTitle} title={page?.introducingNorms?.items?.[0]?.title} titleClassName='md:max-w-[60rem] text-body-copy--light' imgURL={page?.introducingNorms?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.introducingNorms?.items?.[0]?.mediaPrimary?.description} objectFit='contain' textContainerClassName='text-body-copy--light' childrenElements={<>
            <RenderBodyCopy className='t2xl:max-w-[78.2rem]' bodyCopy={page?.introducingNorms?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.introducingNorms?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <ImageTextSecondary id={page?.centreOfPressure?.items?.[0]?.scrollTo?.slice(1)} background='lg:!ml-[unset] lg:!mr-[unset] lg:!w-[100%]' pretitle={page?.centreOfPressure?.items?.[0]?.preTitle} title={page?.centreOfPressure?.items?.[0]?.title} titleClassName='md:max-w-[46rem]' imgURL={page?.centreOfPressure?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.centreOfPressure?.items?.[0]?.mediaPrimary?.description} objectFit='contain' childrenElements={<>
            <RenderBodyCopy className='2xl:max-w-[78.2rem]' bodyCopy={page?.centreOfPressure?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.centreOfPressure?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <Container id={page?.fitForPurpose?.items?.[0]?.scrollTo?.slice(1)} className='!mb-[0rem] py-[7.4rem] md:flex md:flex-col' background='bg-background-tertiary' data-sentry-element="Container" data-sentry-source-file="ForceDecksPage.client.tsx">
        <SectionHeader className='justify-center md:mb-[3.4rem] lg:pb-[unset]' pretitle={page?.fitForPurpose?.items?.[0]?.preTitle} title={page?.fitForPurpose?.items?.[0]?.title} titleClassName='max-w-[71.6rem] text-[2.8rem] mb-[2.4rem] leading-[3.2rem] md:leading-[4.6rem] lg:text-[4.8rem] lg:leading-[5rem]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="ForceDecksPage.client.tsx">
          <>
            <RenderBodyCopy className='2xl:max-w-[78.2rem]' bodyCopy={page?.fitForPurpose?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="ForceDecksPage.client.tsx" />
            <RenderButtons className='justify-center' items={page?.fitForPurpose?.items?.[0]?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="ForceDecksPage.client.tsx" />
          </>
        </SectionHeader>

        <InlineSVG className='mt-[4rem] md:hidden' src={page?.fitForPurpose?.items?.[0]?.mediaTertiary?.url} data-sentry-element="InlineSVG" data-sentry-source-file="ForceDecksPage.client.tsx" />

        <InlineSVG className='mb-[4rem] hidden md:block lg:hidden' src={page?.fitForPurpose?.items?.[0]?.mediaSecondary?.url} data-sentry-element="InlineSVG" data-sentry-source-file="ForceDecksPage.client.tsx" />

        <InlineSVG className='hidden lg:block' src={page?.fitForPurpose?.items?.[0]?.mediaPrimary?.url} data-sentry-element="InlineSVG" data-sentry-source-file="ForceDecksPage.client.tsx" />

        <ImageTextSecondary reversed className='left-[0] !mb-[0] mt-[4.2rem] w-[100%] !gap-[0] !overflow-visible !px-[0] py-[0] 2xl:left-[15%] 2xl:mt-[-8rem] 2xl:w-[75%]' title={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.title} titleClassName='text-[1.6rem] whitespace-nowrap	 lg:text-[2.8rem] 2xl:text-[3.6rem] mb-[2.4rem] leading-[2rem] lg:leading-[3rem] 2xl:text-right 2xl:leading-[4rem] font-[300]' imgURL={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.fitForPurpose?.items?.[0]?.mediaSecondary?.description} imgFirstClassName='hidden lg:block lg:!w-[10rem]' imgSecondClassName='lg:max-w-[20rem] lg:!w-[30%] lg:right-[-10rem]' objectFit='contain' textContainerClassName='2xl:relative 2xl:z-[10] lg:!w-[60%]' childrenElements={<>
              <div className='md:flex'>
                <div className='mb-[3.2rem] md:w-[48rem] lg:w-[100%]'>
                  <RenderBodyCopy className='text-left 2xl:text-right' bodyCopy={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.bodyCopy?.json?.content} />

                  <div className='hidden items-center justify-start md:block lg:!justify-end'>
                    <RenderButtons className='mx-auto !mt-[2.4rem] 2xl:justify-end' items={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.buttonCollection?.items} />
                  </div>
                </div>

                <div>
                  <NextImage className='mx-auto w-[21rem] md:relative md:top-[-13rem] md:mb-[-13rem] md:max-w-[unset] lg:hidden' src={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.mediaSecondary?.url} alt='' aspectRatio={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.mediaSecondary?.width / page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.mediaSecondary?.height} width={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.mediaSecondary?.width}></NextImage>

                  <div className='flex items-center justify-start md:hidden'>
                    <RenderButtons className='mx-auto !mt-[2.4rem]' items={page?.fitForPurpose?.items?.[0]?.extraAssetsCollection?.items?.[0]?.buttonCollection?.items} />
                  </div>
                </div>
              </div>
            </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />
      </Container>

      <ImageTextSecondary className='lg:!mb-[0rem] xl:!overflow-visible' imgSecondClassName='[&>img]:!w-[auto] [&>img]:max-w-[unset] [&>img]:!h-[112%] lg:!flex justify-end !overflow-visible h-[43.8rem]' id={page?.fullyValidate?.items?.[0]?.scrollTo?.slice(1)} pretitle={page?.fullyValidate?.items?.[0]?.preTitle} title={page?.fullyValidate?.items?.[0]?.title} imgURL={page?.fullyValidate?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.fullyValidate?.items?.[0]?.mediaPrimary?.description} objectFit='contain' childrenElements={<>
            <RenderBodyCopy className='lg:max-w-[58rem]' bodyCopy={page?.fullyValidate?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.fullyValidate?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <Container className='!py-[8rem] md:!mb-[0rem] lg:!mb-[0rem] lg:py-[8rem]' background='bg-background-tertiary' data-sentry-element="Container" data-sentry-source-file="ForceDecksPage.client.tsx">
        <ImageTextSecondary reversed className='!mb-[0rem] lg:!px-[0rem]' id={page?.accessories?.items?.[0]?.scrollTo?.slice(1)} pretitle={page?.accessories?.items?.[0]?.preTitle} title={page?.accessories?.items?.[0]?.title} imgURL={page?.accessories?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.accessories?.items?.[0]?.mediaPrimary?.description} objectFit='cover' childrenElements={<>
              <RenderBodyCopy className='2xl:max-w-[78.2rem]' bodyCopy={page?.accessories?.items?.[0]?.bodyCopy?.json?.content} />
              <RenderButtons items={page?.accessories?.items?.[0]?.buttonCollection?.items} />
            </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />
      </Container>

      <ForceDecksBlogsCarousel data={page?.carouselPosts?.items?.[0]} data-sentry-element="ForceDecksBlogsCarousel" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <ImageTextSecondary id={page?.suitYouBusiness?.items?.[0]?.scrollTo?.slice(1)} pretitle={page?.suitYouBusiness?.items?.[0]?.preTitle} title={page?.suitYouBusiness?.items?.[0]?.title} titleClassName='md:max-w-[46rem]' imgURL={page?.suitYouBusiness?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.suitYouBusiness?.items?.[0]?.mediaPrimary?.description} objectFit='contain' childrenElements={<>
            <RenderBodyCopy className='2xl:max-w-[78.2rem]' bodyCopy={page?.suitYouBusiness?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.suitYouBusiness?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <FeaturedProducts data={page?.valdSystems?.items?.[0]} data-sentry-element="FeaturedProducts" data-sentry-source-file="ForceDecksPage.client.tsx" />

      <CallToAction id={page?.callToAction?.items?.[0]?.scrollTo?.slice(1)} title={page?.callToAction?.items?.[0]?.title} bodyCopy={page?.callToAction?.items?.[0]?.bodyCopy?.json?.content} buttons={page?.callToAction?.items?.[0]?.buttonCollection?.items} data-sentry-element="CallToAction" data-sentry-source-file="ForceDecksPage.client.tsx" />
    </>;
};
export default ForceDecksPageClient;